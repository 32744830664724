import React, {useEffect} from "react";
import {FaTimes} from "react-icons/fa";
import PropTypes from "prop-types"


const Modal = ({hidden, children, onDismiss}) => {

  useEffect(() => {
    const close = (e) => {
      if(e.key === 'Escape'){
        onDismiss()
      }
    }
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  },[onDismiss])

  return !hidden && (
    <>
      <div className="fixed top-0 bottom-0 left-0 right-0 bg-black bg-opacity-40 z-10"
           onClick={onDismiss}
           aria-hidden="true"/>
      <div className="fixed text-center bg-white
      top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2
      z-20 border border-white" role="dialog">
        <div className={"flex flex-row justify-end"}>
          <button className="h-8 w-8 text-lg p-0 text-primary text-center"
                  onClick={onDismiss}
                  tabIndex={0}
                  aria-label="Close">
            <FaTimes className={"mx-auto"}/>
          </button>
        </div>
        <div className={"pt-4 pb-12 px-16"}>
          {children}
        </div>
      </div>
    </>
  );
}

Modal.propTypes = {
  hidden: PropTypes.bool,
  children: PropTypes.node.isRequired,
  onDismiss: PropTypes.func.isRequired
}

Modal.defaultProps = {
  hidden: true
}

export default Modal;
