import {FaCheck} from "react-icons/fa";
import * as React from "react";
import {useState} from "react";
import {uniqueId} from "lodash";
import PropTypes from "prop-types";

const Checkbox = ({name, checked, onChange, size, children}) => {
  const [id] = useState(uniqueId('checkbox-'))
  return (
    <div className={"flex items-center"}>
      <input type="checkbox"
             id={id}
             name={name}
             checked={checked}
             className={"appearance-none p-0 border-none focus:outline-none focus:border-none"}
             onChange={onChange}/>
      <label htmlFor={id} className="flex items-start gap-2 cursor-pointer align-top inline-block">
        <div className={"bg-white border border-black rounded-sm p-0.5"}>
          <span className={`text-xs ${checked ? 'text-black' : 'text-white'}`}>
            <FaCheck/>
          </span>
        </div>
        <div className={`${size === 'small' && 'text-sm'} relative -top-1`}>
          {children}
        </div>
      </label>
    </div>
  )
}

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
}

Checkbox.defaultProps = {
  hidden: true
}

export default Checkbox
