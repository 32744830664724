import React from "react";

export default function PulseLoader() {
  return (
    <div className="flex flex-row">
      <div className="animate-shrink-1 h-3 w-3 bg-white rounded-full m-0.5"/>
      <div className="animate-shrink-2 h-3 w-3 bg-white rounded-full m-0.5"/>
      <div className="animate-shrink-3 h-3 w-3 bg-white rounded-full m-0.5"/>
    </div>
  );
}
