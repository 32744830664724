import Layout from "../components/layout";
import * as React from "react";
import {useCallback, useContext, useEffect, useState} from "react";
import Modal from "../components/modal";
import Checkbox from "../components/Checkbox";
import {validateEmail, validatePhone} from "../utils";
import FileUpload from "../components/FileUpload";
import BackLink from "../components/BackLink";
import {graphql, navigate} from "gatsby";
import { FormContext } from "../components/FormContext";
import BlockContent from "../components/BlockContent";
import PulseLoader from "../components/PulseLoader";
import {isEqual} from "lodash";

const UploadPage = ({data}) => {

  const upload = data.sanityUpload;
  const {form, setForm} = useContext(FormContext);

  const [error, setError] = useState({
    name: null,
    email: null,
    phone: null
  });

  const [touched, setTouched] = useState({
    name: false,
    email: false,
    phone: false,
    files: false,
    gdprConfirmed: false,
  });

  const [modal, setModal] = useState({
    title: "",
    message: "",
    hidden: true
  });

  const [isLoading, setLoading] = useState(false);
  const [isDisabled, setDisabled] = useState(true);

  useEffect(() => {
    const updatedError = {
      name: form.name === '' ? 'Please enter your name.' : null,
      email: !validateEmail(form.email) ? 'Please enter a valid email.' : null,
      phone: !validatePhone('+' + form.phone) ? 'Please enter a valid phone number.' : null
    };
    setError(updatedError);
  }, [form])

  useEffect(() => {
    const isError = Object.keys(error)
      .map(key => error[key])
      .filter(error => error !== null)
      .length || !form.gdprConfirmed || !form.files.length;
    setDisabled(!!isError);
  }, [error, form.gdprConfirmed, form.files.length])

  const createFormBody = (form) => {
    const formData = new FormData();
    formData.append('name', form.name);
    formData.append('email', form.email);
    formData.append('phone', '+' + form.phone);
    formData.append('gdprConfirmed', form.gdprConfirmed);
    formData.append('cv', form.files[0]);
    return formData;
  }


  const handleSubmit = async (ev) => {
    ev.preventDefault();
    let title = 'Sorry!';
    let message = `The CV upload is not available yet.`;
    if (!process.env.GATSBY_API_URL) {
      setModal({
        hidden: false,
        title,
        message
      });
      return;
    }
    setDisabled(true);
    setLoading(true);
    const res = await fetch(`${process.env.GATSBY_API_URL}/cv`, {
      method: "POST",
      body: createFormBody(form)
    });
    setLoading(false);
    if (!res.ok) {
      const response = await res.json();
      title = upload.formFailTitle;
      message = response?.message || upload._rawFormFailMessage;
      setDisabled(false);
      setModal({
        hidden: false,
        title,
        message
      });
      return;
    }
    await navigate("/cv-uploaded/")
    setForm({
      name: '',
      email: '',
      phone: '',
      gdprConfirmed: false,
      files: []
    });
    setTouched({
      name: false,
      email: false,
      phone: false,
      gdprConfirmed: false,
      files: false
    });
  }

  const handleChange = useCallback((ev) => {
    let updated = ev.target.type === 'checkbox' ? ev.target.checked : ev.target.value;
    if (isEqual(form[ev.target.name], updated)) {
      return;
    }
    if (ev.target.name === 'phone' && updated.startsWith('+')) {
      updated = updated.substring(1);
    }
    setForm({
      ...form,
      [ev.target.name]: updated
    });
    setTouched(t => ({
      ...t,
      [ev.target.name]: true
    }));
  }, [form, setForm])

  const handleBlur = (ev) => {
    setTouched({
      ...touched,
      [ev.target.name]: true
    });
  }

  const handleModalDismiss = () => {
    setModal({
      hidden: true,
      message: '',
      title: ''
    });
  }

  const showError = (inputName) => {
    return error[inputName] && touched[inputName];
  }

  return (
    <>
      <Layout title={upload.title}>
        <section className="max-w-screen-md mx-auto mb-12">
          <BackLink/>
          <div className={'bg-gray-light p-8 mt-4'}>
            <h1 className="text-2xl text-gray-dark mb-8">
              {upload.formTitle}
            </h1>

            <form name="rsvp" className={"flex flex-col gap-8 md:gap-4 text-base"} onSubmit={handleSubmit}>
              <div className={"flex flex-col"}>
                <label htmlFor={'name'} className={"uppercase text-xs mb-1 text-gray-medium font-semibold"}>Full Name</label>
                <input type={'text'}
                       id={'name'}
                       name={'name'}
                       value={form.name}
                       className={`${showError('name') && 'border-red-600'}`}
                       onChange={handleChange}
                       onBlur={handleBlur}/>
                { showError('name') && <div className={"text-xs mt-1 text-red-600"}>{error.name}</div>}
              </div>
              <div className={"flex flex-col"}>
                <label htmlFor={'email'} className={"uppercase text-xs mb-1 text-gray-medium font-semibold"}>Email</label>
                <input type={'email'}
                       id={'email'}
                       name={'email'}
                       value={form.email}
                       className={`${showError('email') && 'border-red-600'}`}
                       onChange={handleChange}
                       onBlur={handleBlur}/>
                { showError('email') && <div className={"text-xs mt-1 text-red-600"}>{error.email}</div>}
              </div>
              <div className={"flex flex-col"}>
                <label htmlFor={'email'} className={"uppercase text-xs mb-1 text-gray-medium font-semibold"}>Phone</label>
                <div className={'relative bg-white rounded'}>
                  <input type={'phone'}
                         id={'phone'}
                         name={'phone'}
                         value={form.phone}
                         placeholder={'44 1245567890'}
                         className={`${showError('phone') && 'border-red-600'} relative pl-8 w-full bg-transparent`}
                         onChange={handleChange}
                         onBlur={handleBlur}/>
                  <div className={`rounded-r-none shadow-inner-border-r top-0 left-0 border border-transparent bg-clip-padding absolute h-full flex items-center font-bold px-2 bg-gray-light rounded`}>+</div>
                </div>
                { showError('phone') && <div className={"text-xs mt-1 text-red-600"}>{error.phone}</div>}
              </div>
              <div>
                <div className={"uppercase text-xs mb-1 text-gray-medium font-semibold"}>
                  CV/Resume
                </div>
                <FileUpload name={'files'}
                            accept={'application/pdf'}
                            multiple={false}
                            value={form.files}
                            onChange={handleChange}/>
              </div>
              <div className={"mt-8"}>
                <Checkbox name={`gdprConfirmed`}
                          checked={form.gdprConfirmed}
                          onChange={handleChange}
                          size={'small'}>
                  <BlockContent blocks={upload._rawFormPrivacyConfirm}/>
                </Checkbox>
              </div>
              <button className="p-4 ml-6 h-14 cursor-pointer text-white bg-primary disabled:opacity-60 disabled:cursor-default flex items-center justify-center"
                      disabled={isDisabled}
                      type="submit">
                {isLoading ? <PulseLoader/> : upload.formSubmitButton}
              </button>
            </form>
          </div>
        </section>
      </Layout>
      <Modal hidden={modal.hidden} onDismiss={handleModalDismiss}>
        <h1 className={'text-left'}>{modal.title}</h1>
        <div className={'text-left'}>
          <BlockContent blocks={modal.message}/>
        </div>
      </Modal>
    </>
  )
}

export const query = graphql`
  query {
    sanityUpload {
      title
      _rawFormPrivacyConfirm(resolveReferences: {maxDepth: 5})
      formSubmitButton
      formTitle
      formSuccessTitle
      _rawFormSuccessMessage(resolveReferences: {maxDepth: 5})
      formFailTitle
      _rawFormFailMessage(resolveReferences: {maxDepth: 5})
    }
  }
`

export default UploadPage
