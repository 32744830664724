import isMobilePhone from 'validator/es/lib/isMobilePhone';
import isEmail from 'validator/es/lib/isEmail';

export const validateEmail = (email) => {
  return isEmail(email);
};

export const validatePhone = (phone) => {
  return isMobilePhone(phone, 'any', { strictMode: true });
}
