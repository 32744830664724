import {FaCloudUploadAlt, FaFile, FaMinusCircle} from "react-icons/fa";
import * as React from "react";
import {useState} from "react";
import {uniqueId} from "lodash";
import PropTypes from "prop-types";

const FileUpload = ({ name, multiple, accept, onChange, maxSize, maxFiles, value }) => {
  const [id] = useState(uniqueId('fileInput-'));
  const [error, setError] = useState();
  const [isTouched, setTouched] = useState(false);

  const handleFileInput = (ev) => {
    addFiles(ev.target.files);
    ev.target.value = '';
  }

  const addFiles = (files) => {
    const filesMap = {}
    for (const file of value) {
      filesMap[file.name] = file;
    }
    for (const file of files) {
      if (file.size >= maxSize) {
        setError(files.length > 1 ? 'One of the selected files it too big.' : 'The selected file is too big.')
        return;
      }
      if (!accept.split(',').includes(file.type)) {
        setError('Wrong file type')
        return;
      }
      filesMap[file.name] = file;
    }
    if (multiple && Object.keys(filesMap).length > maxFiles) {
      setError(`You can only add at most ${maxFiles} files.`);
      return;
    }
    if (!Object.keys(filesMap).length) {
      setError('Please upload your CV.')
    } else {
      setError(undefined);
    }
    emitOnChange(Object.values(filesMap));
    setTouched(true);
  }

  const emitOnChange = (files) => {
    const filesEqual = (filesA, filesB) => {
      if (filesA.length !== filesB.length) {
        return false;
      }
      filesA.forEach((file, index) => {
        if (
          file.name !== filesB[index].name ||
          file.type !== filesB[index].type ||
          file.size !== filesB[index].size
        ) {
          return false;
        }
      });
      return true;
    }
    if (filesEqual(files, value)) {
      // don't emit event if files have been changed from the outside
      return;
    }
    onChange({
      target: {
        name,
        value: files
      }
    });

  }

  const removeFile = (event, fileName) => {
    event.preventDefault();

    const updated = value.filter(file => file.name !== fileName);
    emitOnChange(updated)
  }

  const fileSizeToString = (number) => {
    if(number < 1024) {
      return number + 'bytes';
    } else if(number >= 1024 && number < 1048576) {
      return (number/1024).toFixed(0) + 'KB';
    } else if(number >= 1048576) {
      return (number/1048576).toFixed(0) + 'MB';
    }
  }

  const [activeDragZone, setActiveDragZone] = useState(0);

  const handleDragOver = (ev) => {
    ev.stopPropagation();
    ev.preventDefault();
  }

  const handleDragEnter = (ev) => {
    ev.stopPropagation();
    ev.preventDefault();
    setActiveDragZone(activeDragZone + 1);
  }

  const handleDragLeave = (ev) => {
    ev.stopPropagation();
    ev.preventDefault();
    setActiveDragZone(activeDragZone - 1);
  }

  const handleDrop = (ev) => {
    ev.stopPropagation();
    ev.preventDefault();
    setActiveDragZone(0);
    addFiles(ev.dataTransfer.files);
  }

  return (
    <>
      <input id={id}
             type='file'
             name={name}
             multiple={multiple}
             accept={accept}
             className={"absolute opacity-0 -z-10 w-1"}
             onChange={handleFileInput}/>
      <label htmlFor={id}
             onDragOver={handleDragOver}
             onDragEnter={handleDragEnter}
             onDragLeave={handleDragLeave}
             onDrop={handleDrop}
             className={`h-32 bg-white rounded border border-dashed 
             ${activeDragZone && 'border-primary'}
             flex flex-col items-center justify-center text-gray-dark cursor-pointer`}>
        <FaCloudUploadAlt className={"text-3xl"}/>
        <div className="text-sm">
          Drag and drop or <span className={'text-black underline font-semibold'}>browse files</span>
        </div>
      </label>
      <div className={'text-tiny text-gray-medium uppercase mt-0.5'}>.PDF (Max. {fileSizeToString(maxSize)})</div>
      { isTouched && error && <div className={"text-xs mt-1 text-red-600"}>{error}</div>}
      <div className={'mt-4'}>
      {
        value.map((file, index) =>
          <div key={`file-${index}`} className={'text-sm flex items-center justify-between max-w-xs'}>
            <div className={'flex items-center gap-1 min-w-0'}>
              <FaFile/>
              <div className={"truncate"}>{file.name}</div>
            </div>
            <button className={'text-gray-medium hover:text-black'} onClick={(ev) => removeFile(ev, file.name)}>
              <FaMinusCircle/>
            </button>
          </div>
        )
      }
      </div>
    </>
  )
}

FileUpload.propTypes = {
  name: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  accept: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  maxSize: PropTypes.number,
  maxFiles: PropTypes.number
}

FileUpload.defaultProps = {
  multiple: true,
  maxSize: 5 * 1024 * 1024,
  maxFiles: 5
}

export default FileUpload
